import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTelegram,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { Link } from 'react-router-dom';

import { PATHS } from '@/constants/page-paths';
import { useAuth } from '@/lib/hooks/useAuth';
import { useSettingsStore } from '@/store';

const socialIcons: Record<string, JSX.Element> = {
  x: <FaXTwitter className="text-muted-foreground" />,
  linkedin: <FaLinkedinIn className="text-muted-foreground" />,
  instagram: <FaInstagram className="text-muted-foreground" />,
  facebook: <FaFacebook className="text-muted-foreground" />,
  youtube: <FaYoutube className="text-muted-foreground" />,
  telegram: <FaTelegram className="text-muted-foreground" />,
};

export const Footer = () => {
  const { settings } = useSettingsStore();
  const { isAuthenticated } = useAuth();
  const socialLinks = settings?.ui?.links?.socialLinks || [];
  const footerLinks = settings?.ui?.links?.footerLinks || [];
  return (
    <footer className="flex sm:flex-row flex-col-reverse justify-center gap-2 md:gap-4 items-center text-xs sm:text-sm md:justify-between p-2 sm:px-6 md:px-8 border-t">
      <p className="flex flex-row gap-4">
        © {new Date().getFullYear()} {settings?.ui?.organizationName}
        {!isAuthenticated && <Link to={PATHS.FEEDBACK}>Help & Feedback</Link>}
      </p>

      <div className="flex gap-2 md:gap-4 items-center justify-center">
        {socialLinks.map((link) => (
          <a
            key={link.platform}
            href={link.url}
            target="_blank"
            rel="noreferrer"
            className="text-muted-foreground text-xs md:text-base lg:text-lg"
          >
            {socialIcons[link.platform as string]}
          </a>
        ))}
        <p className="flex items-center divide-x-2">
          {footerLinks.map((link) => (
            <a
              key={link.label}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              className="text-muted-foreground px-2"
            >
              {link.label}
            </a>
          ))}
        </p>
      </div>
    </footer>
  );
};
