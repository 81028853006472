import { useState } from 'react';

import { LogOut } from 'lucide-react';

import { useAuth } from '@/lib/hooks/useAuth';
import { Button } from '../ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from '../ui/dialog';

export const Logout = ({ children }) => {
  const { signOut, isSingOutPending } = useAuth();

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  return (
    <Dialog open={logoutModalOpen} onOpenChange={setLogoutModalOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-sm flex flex-col items-center text-center">
        <div className="p-2 rounded-full bg-secondary/50 w-32 h-32 flex items-center text-primary justify-center">
          <LogOut size={60} />
        </div>
        <div>
          <h3>Log out</h3>
          <p>Are you sure you want to log out?</p>
        </div>
        <div className="flex gap-4 justify-end">
          <DialogClose asChild>
            <Button type="button" variant="outline">
              Cancel
            </Button>
          </DialogClose>

          <Button
            variant="destructive"
            onClick={() => signOut()}
            loading={isSingOutPending}
          >
            Log out
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
