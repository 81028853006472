import { useState } from 'react';

import { AlignLeft, X } from 'lucide-react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import { navItems } from '@/configs/nav/nav-items';
import { MouseEvent } from '@/types';

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (e: MouseEvent) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  const handleNavLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="ghost"
        size="icon"
        className="lg:hidden"
        onClick={toggleMenu}
      >
        <AlignLeft className="h-6 w-6 md:h-8 md:w-8" />
      </Button>
      <div
        className={`fixed top-0 left-0 z-50 h-screen w-full bg-opacity-50 backdrop-filter backdrop-blur-lg bg-background/75 transition-transform transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <Button
          size="icon"
          onClick={toggleMenu}
          className="absolute top-4 right-4 z-10"
        >
          <X size={32} />
        </Button>

        <ul className="flex-1 flex items-center justify-center flex-col h-full w-full text-center">
          {navItems.map((item, i) => (
            <li key={i} className="w-full">
              <NavLink
                to={item.path}
                onClick={handleNavLinkClick}
                className={({ isActive }) =>
                  `text-2xl py-6 transition-all hover:bg-muted w-full block ${
                    isActive ? 'text-primary bg-muted' : 'text-foreground'
                  }`
                }
              >
                {item.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
