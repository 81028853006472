import api from '@/services';

export const apiChatResponse = async (payload: {
  query: string;
  wordLimit?: number;
  twinName?: string;
}) => {
  return await api.post('/twin/chat', payload);
};

export const apiGetTwin = async (payload: {
  twinName?: string;
  testToken?: string;
}) => {
  return await api.get('/twin/get-twin', { params: payload });
};

export const apiGetSpeechToken = async (payload: { testToken?: string }) => {
  return await api.get('/twin/get-speech-token', { params: payload });
};

export const apiGetTwinCategories = async (payload: { twinName: string }) => {
  return await api.get('/twin/categories', { params: payload });
};

export const apiGetChatHistory = async () => {
  return await api.get('/twin/chat-details');
};

export const apiGetTwinChatHistory = async (payload: {
  twinId: string;
  brainId: string;
}) => {
  return await api.get('/twin/chat-history', { params: payload });
};
