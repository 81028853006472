import { Link } from 'react-router-dom';

import { PATHS } from '@/constants/page-paths';
import { cn } from '@/lib/utils/cn';
import { useSettingsStore } from '@/store';

export const Logo = ({ className }: { className?: string }) => {
  const { themeMode, settings } = useSettingsStore();

  const logo =
    themeMode === 'light'
      ? settings?.ui?.theming?.logo?.light
      : settings?.ui?.theming?.logo?.dark;

  const classes = cn(className, 'object-contain');

  return (
    <Link to={PATHS.HOME}>
      <img src={logo} className={classes} />
    </Link>
  );
};
