import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { mainnet, polygonAmoy } from 'wagmi/chains';

const devMode = import.meta.env.VITE_TEST_MODE === 'true' ? true : false;
const wagmiProjectId = import.meta.env.VITE_RAINBOWKIT_PROJECT_ID ?? '';

export const config = getDefaultConfig({
  appName: 'Twin Protocol',
  projectId: wagmiProjectId,
  chains: devMode ? [polygonAmoy] : [mainnet],
});
