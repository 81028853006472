import twinCoin from '@/assets/icons/twin-coin-blue.svg';
import twinToken from '@/assets/icons/twin-token.svg';

const TwinCoin = ({
  size = 6,
  tokenIcon = false,
}: {
  size?: number;
  tokenIcon?: boolean;
}) => {
  return (
    <img
      src={tokenIcon ? twinToken : twinCoin}
      className={`w-${size} h-${size} items-center justify-center flex`}
    />
  );
};

export default TwinCoin;
