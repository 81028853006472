import { NavLink } from 'react-router-dom';

import { navItems } from '@/configs/nav/nav-items';
import { cn } from '@/lib/utils/cn';
import { useSettingsStore } from '@/store';

export const DesktopMenu = () => {
  const { settings } = useSettingsStore();

  const themedUi = settings?.ui?.theming?.themedUi;
  return (
    <ul className="hidden lg:flex items-center gap-6 h-full">
      {navItems.map((item, i) => (
        <li key={i} className="h-full">
          <NavLink
            to={item.path}
            className={({ isActive }) =>
              cn(
                'border-b-2 transition-all text-sm h-full flex items-center',
                isActive
                  ? themedUi
                    ? 'border-primary-foreground'
                    : 'border-primary'
                  : 'border-transparent'
              )
            }
          >
            {item.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
