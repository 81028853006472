import { useEffect } from 'react';

import { ProtectedRouteInterface } from '@/configs/routes/protected-routes';
import { APP_CONFIG } from '@/constants/app-config';
import { useTwin } from '@/lib/hooks/api/twin.hook';
import { useAuth } from '@/lib/hooks/useAuth';
import { useSettingsStore } from '@/store';

const AppRoute = ({ ...props }: ProtectedRouteInterface) => {
  const { component: Component, name, path } = props;
  const { isAuthenticated } = useAuth();
  const { settings } = useSettingsStore();
  const { twinData } = useTwin();
  const PersonalityName = twinData?.personalityName;

  useEffect(() => {
    if (isAuthenticated) {
      const title = path.startsWith('/chat/')
        ? `${PersonalityName ?? 'Chat'} | ${settings?.ui?.appName || APP_CONFIG.appName}`
        : name
          ? `${name} | ${settings?.ui?.appName || APP_CONFIG.appName}`
          : APP_CONFIG.appName;
      document.title = title;
    } else {
      const title = name
        ? `${name} | ${settings?.ui?.appName || APP_CONFIG.appName}`
        : APP_CONFIG.appName;
      document.title = title;
    }
  }, [isAuthenticated, name, path, settings?.ui?.appName, PersonalityName]);

  return <Component />;
};

export default AppRoute;
