import React from 'react';

import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import AppConfigProvider from '@/components/layouts/AppConfigProvider';
import App from './App.tsx';
import { queryClient } from './lib/hooks/useQuery.ts';

import '@/configs/sentry';
import './index.css';

import RainbowKitWrapper from './components/layouts/RainbowKitWrapper';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppConfigProvider>
          <RainbowKitWrapper>
            <App />
          </RainbowKitWrapper>
        </AppConfigProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
