import { Navigate } from 'react-router-dom';

import { APP_CONFIG } from '@/constants/app-config';
import { useAuth } from '@/lib/hooks/useAuth';
import useUrlParams from '@/lib/hooks/useParams';
import AuthLayout from './AuthLayout';

const { authenticatedEntryPath } = APP_CONFIG;

export const PublicRoute = () => {
  const { isAuthenticated } = useAuth();
  const params = useUrlParams();

  const redirectUrl =
    params.get(APP_CONFIG.REDIRECT_URL_KEY) ?? authenticatedEntryPath;

  if (isAuthenticated) {
    return <Navigate replace to={redirectUrl} />;
  }

  return <AuthLayout />;
};
