import { AxiosResponse } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from 'react-query';

import {
  apiGetProfile,
  apiGetUserPoints,
  apiUpdateProfile,
} from '@/services/user.api';
import { invalidateQuery } from '../useQuery';

export const useGetProfile = (
  options?: UseQueryOptions<any, Error>
): UseQueryResult<any, Error> => {
  return useQuery<any, Error>({
    queryKey: ['profile'],
    queryFn: apiGetProfile,
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useUpdateProfile = (
  options?: UseMutationOptions<AxiosResponse<any>, Error, any>
): UseMutationResult<AxiosResponse<any>, Error, any> => {
  return useMutation<AxiosResponse<any>, Error, any>({
    mutationFn: apiUpdateProfile,
    onSuccess: () => {
      invalidateQuery(['profile']);
    },
    ...options,
  });
};

export const usePoints = (options?: UseQueryOptions<any, Error>) => {
  return useQuery({
    queryKey: ['userPoints'],
    queryFn: apiGetUserPoints,
    ...options,
  });
};
