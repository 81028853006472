// RainbowKitWrapper.tsx
import React from 'react';

import '@rainbow-me/rainbowkit/styles.css';

import {
  darkTheme,
  lightTheme,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
  QueryClient,
  QueryClientProvider as TanstackProvider,
} from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';

import { config } from '@/lib/helpers/wagmi.ts'; // Adjust the path as needed

const tanQueryClient = new QueryClient();

const RainbowKitWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <WagmiProvider config={config}>
      <TanstackProvider client={tanQueryClient}>
        <RainbowKitProvider
          theme={{
            lightMode: lightTheme(),
            darkMode: darkTheme(),
          }}
        >
          {children}
        </RainbowKitProvider>
      </TanstackProvider>
    </WagmiProvider>
  );
};

export default RainbowKitWrapper;
