export const PATHS = {
  HOME: '/',
  CHAT: '/chat',
  SIGN_IN: '/sign-in',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_OTP: '/verify-otp',
  PROFILE: '/profile',
  MY_TWIN: '/my-twin',
  TWINS: '/twins',
  GOOGLE: '/oauth/google',
  BUY_POINTS: '/buy-points',
  SUBSCRIBED_TWINS: '/subscribed-twins',
  EXPLORE_TWINS: '/explore-twins',
  FEEDBACK: '/feedback',
};
