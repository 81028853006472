import api from '@/services';

export const apiGetProfile = async () => {
  return await api.get('/user/profile');
};

export const apiUpdateProfile = async (payload: {
  firstName?: string;
  lastName?: string;
  currentPassword?: string;
  newPassword?: string;
  phoneNumber?: string;
  file?: string;
  otp?: string;
}) => {
  return await api.patch('/user/profile', payload);
};

export const apiSendEmailOtp = async () => {
  return await api.post('/auth/send-otp');
};

export const apiUpdateEmail = async (payload: {
  newEmail?: string;
  otp?: string;
  password?: string;
}) => {
  return await api.post('/user/change-email', payload);
};
export const apiJoinWaitlist = async (payload) => {
  return await api.post('/twin/join-waitList', payload);
};
export const apiMyTwin = async () => {
  return await api.get('/user/my-twin');
};

export const apiGetPointsPackages = async () => {
  return await api.get('/app/token-package');
};

export const apiGetStripeFinalPrice = async (payload: {
  productId: string;
}) => {
  return await api.get('/payment/stripe/price', { params: payload });
};

export const apiSendFeedback = async (formData: FormData) => {
  return await api.post('/twin/send-feedback', formData);
};

export const apiGetUserPoints = async () => {
  return await api.get('/user/points');
};

export const apiGetTransactions = async (payload) => {
  return await api.get('/user/transaction-history', { params: payload });
};

export const apiGetVault = async () => {
  return await api.get('/user/vault');
};

export const apiSendInvites = async (payload) => {
  return await api.post('/user/send-invite', payload);
};

export const apiCheckoutWithTwinToken = async (payload: {
  packageId: string;
  hash: string;
}) => {
  return await api.post('/payment/vault/token-to-points', payload);
};

export const apiCheckTwinNameAvailability = async (payload) => {
  return await api.post('/twin/name-availability', payload);
};
