import { useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { PATHS } from '@/constants/page-paths';
import { apiGetTwin } from '@/services/chat.api';
import { useChatStore, useTwinStore } from '@/store';

export const useTwin = (twin?: string) => {
  const { setTwin, twin: savedTwin } = useTwinStore();

  const { state } = useLocation();

  const { brainId: newCatId } = state || {};

  const { twinName, demoToken } = useParams();

  const { setCategory, category } = useChatStore();

  const navigate = useNavigate();

  const twinParam = twinName || twin || savedTwin?.name;

  const { data, ...others } = useQuery({
    queryKey: ['twin', twinName, demoToken],
    queryFn: () =>
      apiGetTwin({
        twinName: !demoToken ? twinParam : null,
        testToken: demoToken,
      }),
    onSuccess: (res) => {
      setTwin(res?.data?.data);
      const categories = res?.data?.data?.categoryDetails;

      if (newCatId) {
        const newCategory = categories?.find((i) => i?.brainId === newCatId);
        if (newCategory) {
          setCategory(newCategory);
        }
      } else {
        const isExistingCategoryValid = categories?.some(
          (i: Record<string, string>) => i?.brainId === category?.brainId
        );

        if (!isExistingCategoryValid) {
          setCategory(res?.data?.data?.defaultCategory);
        } else {
          const cat = categories?.find(
            (i: Record<string, string>) =>
              i?.brainId === res?.data?.data?.defaultCategory?.brainId
          );
          setCategory(cat);
        }
      }
    },
    onError: () => {
      navigate(PATHS.HOME);
    },
    refetchOnWindowFocus: false,
  });

  const twinData = data?.data?.data;

  return {
    twinData,
    ...others,
  };
};

// export const useTwinCategories = (twin?: string, enabled = true) => {
//   const { setCategory } = useChatStore();
//   const { twinName } = useParams();
//   const twinParam = twinName || twin;

//   const { data, ...others } = useQuery({
//     queryKey: ['twinCategories', twinName],
//     queryFn: () => apiGetTwinCategories({ twinName: twinParam ?? '' }),
//     onSuccess: (res) => {
//       const categories = res?.data?.data?.categoryDetails;
//       const cat = categories?.find(
//         (i: Record<string, string>) =>
//           i?.brainId === res?.data?.data?.defaultCategory?.brainId
//       );

//       setCategory(cat);
//     },
//     refetchOnWindowFocus: false,
//     cacheTime: 0,
//     staleTime: 0,
//     enabled,
//   });

//   const categories = data?.data?.data?.categoryDetails || [];
//   const defaultCategory = data?.data?.data?.defaultCategory;

//   return {
//     categories,
//     defaultCategory,
//     ...others,
//   };
// };
